<template>
  <div>
    <div class="SendSMSMobile">
      <el-descriptions
        :title="Title"
        :column="1"
        border
        labelClassName="leftKey"
      >
        <el-descriptions-item
          v-for="(item, index) in formData"
          :key="index"
          :label="item.Key"
        >
          <span v-if="item.Keys == 'FullName'">{{ FullName }}</span>
          <span v-else-if="item.Keys == 'SigningDate'">{{
            SigningDate ? SigningDate.substring(0, 10) : ""
          }}</span>
          <span v-else-if="item.Keys == 'ReceiptPhone'">{{
            ReceiptPhone
          }}</span>
          <span v-else-if="item.Keys == 'IdCard'">{{ IdCard }}</span>
          <span v-else>{{ item.value }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <div
        style="margin-top: 10px"
        v-for="(item, index) in tableData"
        :key="index"
      >
        <el-table :data="item.datalist" header-row-class-name="tableHeader">
          <el-table-column
            v-for="(im, ix) in item.columns"
            :key="ix"
            :prop="im.dataIndex"
            :label="im.title"
            min-width="110"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="signAndSeal">
        <div class="Seal Box" v-if="zdshow">
          <span>制单人 &nbsp;{{ SealName }}</span>
          <img v-if="Seal" :src="Seal" alt="" />
        </div>
        <div class="Sign Box" v-if="qsshow">
          <span>签收人</span>
          <img v-if="Sign" :src="Sign" alt="" />
        </div>
      </div>
    </div>
    <!-- 司机确认按钮 -->
    <el-button
      :disabled="show"
      type="primary"
      @click="confirm"
      class="confirmBtn"
      >{{ show ? "当前回执已确认" : "保存到我的回执" }}</el-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: [], // 整体表单
      tableData: [], // 表格渲染
      imgs: [], // 附近
      billid: "", // 表单ID
      Sign: "", // 签名
      Seal: "", // 签章
      SealName: "",
      Title: "", // 标题
      sendMessage: "", // 转发内容
      smsCode: "",
      showMsg: false,
      zdshow: false,
      qsshow: false,
      Status: 0,
      qrcodeimg: "",
      openid: "",
      show: true,
    };
  },
  async mounted() {
    let query = location.href.split("?");
    if (query.length > 2) {
      console.log(query);
      let code = query[1].split("&")[0].split("=")[1];
      console.log(code);
      // 获取access_token
      let result = await this.$http.get(
        "/Base_Manage/Home/GetWxAccessToken?code=" + code
      );
      console.log(JSON.parse(result.Data));
      let { openid } = { ...JSON.parse(result.Data) };
      this.openid = openid;
      this.billid = this.$route.query.id;
      await this.getFormData();
      await this.getOpenId();
    } else {
      this.$message.error("信息获取失败,请重新扫码尝试");
    }
  },
  methods: {
    // 司机确认
    confirm() {
      this.$http
        .post("/Base/Base_Driverbill/SaveData", {
          BillId: this.billid,
          OpenId: this.openid,
        })
        .then((res) => {
          if (res.Success) {
            this.$message.success("操作成功");
            this.getOpenId();
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    async getFormData() {
      let resJson = await this.$http.post("/BO/BillList/GetBillDetail", {
        id: this.billid,
      });
      if (resJson.Success && resJson.Data) {
        this.smsCode = resJson.Data.SmsCode;
        this.Status = resJson.Data.Status;
        this.Sign = resJson.Data.Sign;
        this.Seal = resJson.Data.Seal;
        this.FullName = resJson.Data.FullName; // 姓名
        this.SigningDate = resJson.Data.SigningDate; // 签收日期
        this.ReceiptPhone = resJson.Data.ReceiptPhone; // 收货人手机号
        this.IdCard = resJson.Data.IdCard; // 身份证号
        if (this.$route.query.type == 1) {
          this.showMsg = true;
          this.sendMessage = resJson.Data.SendMessage.split("_")[1];
        } else {
          this.showMsg = false;
          this.sendMessage = resJson.Data.SendMessage.split("_")[0];
        }

        console.log(this.sendMessage);
        this.imgs = resJson.Data.Imgs ? resJson.Data.Imgs : [];
        // 确认内容解析
        if (
          resJson.Data.Content &&
          JSON.parse(resJson.Data.Content).printlist.length > 0
        ) {
          let arr = JSON.parse(resJson.Data.Content).printlist;
          let fit = ["1", "3", "4", "5", "7", "8", "9", "10"];
          arr.forEach((item) => {
            // 取出非表格/标题/制单人/签收人并排序 横线竖线方框
            // console.log(item.Key, item.type, item.Keys, item.ispringt);
            if (item.Keys == "Salesperson") {
              this.SealName = item.value;
            }
            if (item.Key == "制单人") {
              this.zdshow = true;
            } else if (item.Key == "签收人") {
              this.qsshow = true;
            } else if (fit.findIndex((im) => im == item.type) == -1) {
              this.formData.push(item);
            } else if (item.type == "1" && item.Keys == "Title") {
              this.Title = item.value;
            } else if (item.type == "4") {
              this.tableData.push(item);
            }
          });
          this.formData = this.formData.sort((a, b) => a.index - b.index);
        }
      } else {
        this.$message.error("数据获取失败");
      }
      // 重量 数量合计并赋值
      let num = 0;
      let weight = 0;
      let realNums = 0;
      this.tableData.forEach((item) => {
        item.datalist.forEach((im) => {
          num += Number(im.Quantity);
          weight += Number(im.Weight1);
          realNums += Number(im.nums);
        });
      });
      let numIndx = this.formData.findIndex((item) => item.Keys == "countnums");
      let weightIndx = this.formData.findIndex((item) => item.Keys == "wights");
      let realIndx = this.formData.findIndex((item) => item.Keys == "signnums");
      if (numIndx != -1)
        this.formData[numIndx].value = isNaN(num) ? 0 : num.toFixed(2);
      if (weightIndx != -1)
        this.formData[weightIndx].value = isNaN(weight) ? 0 : weight.toFixed(2);
      if (realIndx != -1)
        this.formData[realIndx].value = isNaN(realNums)
          ? 0
          : realNums.toFixed(2);
    },
    getOpenId() {
      this.$http
        .post("/BO/BillList/IsScancode?Id=" + this.billid)
        .then((res) => {
          if (res.Success) {
            this.show = res.Data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.imgB {
  background-image: url("../../assets/PublicAccountBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.confirmBtn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}
.qianshou {
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  padding-top: 35vh;
  .imgBox {
    padding: 10px;
    border: 1px solid #ccc;
    background: #fff;
    width: 120px;
    margin: 0 auto;
  }
}

/deep/.leftKey {
  width: 100px;
}

/deep/.tableHeader {
  th {
    background: #fafafa;
  }
}

.SendSMSMobile {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;

  .signAndSeal {
    margin-top: 10px;
    display: flex;

    .Box {
      flex: 1;
      position: relative;

      img {
        position: absolute;
        width: 100px;
        left: 50px;
        top: 20px;
      }
    }

    .Sign {
      img {
        transform: rotate(-90deg);
        width: 60px;
        left: 75px;
        top: -30px;
      }
    }
  }
}
</style>
